import '@hotwired/turbo-rails'
import 'arrive'
import * as bootstrap from 'bootstrap'

// stimulus support
import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
const application = Application.start()
const controllers = import.meta.glob('~/**/*_controller.js', { eager: true })
registerControllers(application, controllers)

// main menu mobile collapsing
document.arrive('#menu-inner', { existing: true }, function(el) {
  ['show.bs.collapse', 'hide.bs.collapse'].forEach(function(evt){
    el.addEventListener(evt, function(event){
      document.querySelectorAll('.toggle-icon').forEach(function(el){ el.classList.toggle('d-none') })
    })
  })
})

document.arrive('nav.menu a', { existing: true }, function(el) {
  el.addEventListener('click', function(event) {
    var menu = document.querySelector('#menu-inner')
    var coll = bootstrap.Collapse.getInstance(menu)
    if (coll) { coll.hide() }
  })
})

// scroll to anchor on load when needed
function scrollToAnchor() {
  if (window.location.hash) {
    var el = document.querySelector(window.location.hash)
    if (el) { el.scrollIntoView(true) }
  }
}

// sharer.js
import 'sharer.js'
function initSharer() { Sharer.init() }

// plausible.io + Turbo
function trackVisit() {
  try { plausible('pageview') }
  catch(err) { console.log('Plausible: not available.') }
}

// make all external links open in new tab
document.arrive('.richtext', { existing: true }, function(el) {
  const selector = "a[href^='http']:not([href^='http://pavel.onesim.net']):not([href^='https://pavel.onesim.net'])"
  el.querySelectorAll(selector).forEach((e) => { e.setAttribute("target", "_blank") })
})

// on-load handlers
document.addEventListener('turbo:load', () => {
  trackVisit()
  initSharer()
  scrollToAnchor()
})

window.addEventListener('popstate', scrollToAnchor)

// CSS Styles
import '~/stylesheets/website.scss'
